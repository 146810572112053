/* CustomInfoWindow.css */
.custom-info-window {
    max-width: 500px;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.custom-info-window a {
    color: #22c55e;
}

.custom-info-window a:hover {
    color: #16a34a;
}

.custom-info-window .dark a {
    color: #86efac;
}

.custom-info-window .dark a:hover {
    color: #4ade80;
}

.custom-info-window h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.custom-info-window h3 {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.custom-info-window p {
    color: #4b5563;
    margin-bottom: 0.25rem;
}

.custom-info-window .dark p {
    color: #d1d5db;
}

.custom-info-window ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.custom-info-window li {
    margin-bottom: 0.5rem;
}

/* Bottom Sheet Styles */
.bottom-sheet-enter {
    transform: translateY(100%);
}

.bottom-sheet-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}

.bottom-sheet-exit {
    transform: translateY(0);
}

.bottom-sheet-exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
}

.overlay-enter {
    opacity: 0;
}

.overlay-enter-active {
    opacity: 0.5;
    transition: opacity 300ms ease-in-out;
}

.overlay-exit {
    opacity: 0.5;
}

.overlay-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}
  